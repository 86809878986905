<template>
  <SearchForm ref="searchFormRef" :formModel="searchController.formModel" @search="tableController.search" :searchLoading="tableController.loading">
    <template #form>
      <el-form-item prop="dateType">
        <el-select placeholder="日期类型" v-model="searchController.formModel.dateType" clearable>
          <el-option label="下处方时间" value="RX"></el-option>
          <el-option label="审批时间" value="APPR"></el-option>
          <el-option label="完成时间" value="FINISH"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="dateRange">
        <el-date-picker
          v-model="searchController.formModel.dateRange"
          type="daterange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="YYYY-MM-DD"
          :clearable="false"
          style="height: 32px"
        ></el-date-picker>
      </el-form-item>
      <!-- <el-form-item prop="tradeNo">
        <el-input placeholder="订单编号" v-model="searchController.formModel.tradeNo" clearable></el-input>
      </el-form-item> -->
      <el-form-item prop="rxCode">
        <el-input placeholder="处方编号" v-model="searchController.formModel.rxCode" clearable></el-input>
      </el-form-item>
      <el-form-item prop="doctorName">
        <el-input placeholder="医生姓名" v-model="searchController.formModel.doctorName" clearable></el-input>
      </el-form-item>
      <el-form-item prop="pharmacistName">
        <el-input placeholder="审方人" v-model="searchController.formModel.pharmacistName" clearable></el-input>
      </el-form-item>
      <el-form-item prop="apprStatus">
        <FetchSelect
          placeholder="审方状态"
          v-model="searchController.formModel.apprStatus"
          api="/web/sys/code/getSysCodeByParam"
          :postData="{ codeParam: 'PRESCRIPTION_APPR_STATUS' }"
          textKey="codeValue"
          valueKey="codeKey"
        />
      </el-form-item>
      <el-form-item prop="visitNo">
        <el-input placeholder="咨询单号" v-model="searchController.formModel.visitNo" clearable></el-input>
      </el-form-item>
      <el-form-item prop="saleTradeNo">
        <el-input placeholder="SaaS商城单号" v-model="searchController.formModel.saleTradeNo" clearable></el-input>
      </el-form-item>
      <el-form-item prop="saleStatus">
        <FetchSelect
          placeholder="是否购买"
          v-model="searchController.formModel.saleStatus"
          api="/web/sys/code/getSysCodeByParam"
          :postData="{ codeParam: 'PRESCRIPTION_SALE_STATUS' }"
          textKey="codeValue"
          valueKey="codeKey"
        />
      </el-form-item>
    </template>
    <template #controll>
      <el-button type="primary" @click="exportStream">导出</el-button>
    </template>
  </SearchForm>
  <ProTable
    :columns="tableController.columns"
    :tableData="tableController.tableData"
    :loading="tableController.loading"
    :total="tableController.total"
    :pageState="tableController.pageState"
    @pageChange="tableController.onPageChange"
  />
  <!-- 模态 -->
  <DetailModal ref="DetailModalRef" />
  <ApprModal ref="ApprModalRef" @success="tableController.refresh" />
  <QRCodeCommonModal ref="QRCodeCommonModalRef" />
  <SuperviseModal ref="SuperviseModalRef" />
</template>

<script>
import { ref, computed } from 'vue'
import dayjs from 'dayjs'
import SearchForm from '@/components/SearchForm'
import ProTable from '@/components/ProTable'
import FetchSelect from '@/components/FetchSelect'
import CustomLink from '@/components/customStyle/CustomLink'
import DetailModal from './DetailModal.vue'
import ApprModal from './ApprModal.vue'
import QRCodeCommonModal from '@/components/modal/QRCodeCommonModal'
import useSearchFormCtrller from '@/hooks/useSearchFormCtrller'
import useTableController from '@/hooks/useTableController'
import request from '@/utils/request'
import { simpleConfirm } from '@/utils/confirm'
import { useStore } from 'vuex'
import { getHostUrl } from '@/utils/utils'
import useExport from '@/hooks/useExport'
import SuperviseModal from './SuperviseModal/index.vue'

export default {
  setup() {
    const store = useStore()
    const userInfo = computed(() => store.state.user.userInfo)
    const DetailModalRef = ref(null)
    const ApprModalRef = ref(null)
    const QRCodeCommonModalRef = ref(null)
    const SuperviseModalRef = ref(null)

    // searchForm
    const searchController = useSearchFormCtrller({
      form: {
        dateType: 'RX', // 日期类型(非空 RX-下处方时间;APPR-审批时间;FINISH-完成时间)(默认:RX)
        dateRange: [dayjs().subtract(30, 'day').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')],
        // tradeNo: '', // 订单编号
        rxCode: '', // 处方编号
        pharmacistName: '', // 药剂师 审方人
        apprStatus: '', // 审批状态
        // 8) doctorPsptNo                        医生身份证(精确查询)
        // 11) rxStatus                                处方状态(可多选, 分隔符",")(下拉框,codeParam=PRESCRIPTION_RX_STATUS)
        visitNo: '',
        saleTradeNo: '',
        saleStatus: '',
      },
    })

    const { setExportPostData, exportStream } = useExport('/web/system/prescription/exportPrescriptionList')

    // table
    const columns = [
      { label: '下单时间', prop: 'rxDateStr', width: 180 },
      { label: '咨询单号', prop: 'visitNo', width: 155 },
      { label: '处方编号', prop: 'rxCode', width: 155 },
      { label: '咨询单状态', prop: 'doctorOrderStatusName', width: 90 },
      { label: '咨询单费用', prop: 'doctorOrderFeeStr', width: 95 },
      { label: '医生', prop: 'doctorName', width: 95 },
      { label: 'SaaS商城单号', prop: 'saleTradeNo' },
      { label: '是否购买', prop: 'saleStatusName', width: 80 },
      { label: '审方人', prop: 'pharmacistName', width: 100 },
      { label: '审方时间', prop: 'apprDateStr', width: 180 },
      { label: '审方状态', prop: 'rxStatusName', width: 95 },
      { label: '审核意见', prop: 'apprNote', width: 200 },
      {
        label: '操作',
        prop: 'actions',
        width: 130,
        fixed: 'right',
        render: (_, record) => {
          return (
            <div>
              <CustomLink onClick={() => clickDetail(record)}>详情</CustomLink>
              {record.rxStatus == '0' && <CustomLink onClick={() => clickAppr(record)}>审核</CustomLink>}
              {record.rxSnapshotUrl && <CustomLink onClick={() => window.open(record.rxSnapshotUrl, '_blank')}>处方单</CustomLink>}
              <CustomLink onClick={() => SuperviseModalRef.value?.open(record.rxCode)}>监管信息</CustomLink>
            </div>
          )
        },
      },
    ]

    const tableController = useTableController({
      columns,
      ajax: ({ page, pageSize }) => {
        const { dateRange, ...values } = searchController.formModel
        const postData = {
          page,
          rows: pageSize,
          startDate: dateRange?.[0],
          endDate: dateRange?.[1],
          ...values,
        }
        setExportPostData(postData)
        return request({
          url: '/web/system/prescription/getPrescriptionList',
          data: postData,
        }).then((data) => ({ list: data.data ?? [], totalNum: data.rowTop ?? 0 }))
      },
    })

    function clickDetail(record) {
      DetailModalRef.value?.open(record.rxCode)
    }

    async function clickAppr(record) {
      // 验证
      // 当前登录人 如果是药师，但是还没签名
      if (userInfo.value?.pharmacistInfoDTO) {
        if (!userInfo.value.pharmacistInfoDTO.signImg) {
          await simpleConfirm('请先完善签名再审核')
          // const text = `${getHostUrl()}/yaoshih5/index.html#/h5/pages/login/index?phoneNumber=${record.phoneNumber}`
          const text = `https://dr.bld365.com/yaoshih5/index.html#/h5/pages/login/index?phoneNumber=${record.phoneNumber}`

          QRCodeCommonModalRef.value.open({
            text,
            saveName: '药师_' + record.pharmacistName + '_二维码',
            isShowText: true,
          })
          return
        }
      }
      // 验证 end

      ApprModalRef.value?.open(record)
    }

    return {
      searchController,
      tableController,
      exportStream,
      DetailModalRef,
      ApprModalRef,
      QRCodeCommonModalRef,
      SuperviseModalRef,
    }
  },
  components: { SearchForm, ProTable, FetchSelect, CustomLink, DetailModal, ApprModal, QRCodeCommonModal, SuperviseModal },
}
</script>

<style></style>
