export function saveImgUrl(name, url) {
  let a = document.createElement('a') // 生成一个a元素
  a.target = '_blank'
  a.download = name ?? '图片下载'
  a.href = url
  a.click()
  setTimeout(() => {
    a = null
  }, 0)
}
