<template>
  <StyledModal :visible="visible" title="监管信息" :showCancel="false" @close="close" @submit="close" width="90vw">
    <div>
      <div class="table_box">
        <div class="title">
          <IconVertical />
          <div class="title_text">咨询单信息</div>
        </div>
        <el-table :data="superviseInfo?.doctorOrderTaskList ?? []" style="width: 100%">
          <el-table-column v-for="(column, index) in columns" :key="index" :label="column.title" :prop="column.dataIndex" :width="column.width" />
        </el-table>
      </div>
      <div class="table_box">
        <div class="title">
          <IconVertical />
          <div class="title_text">处方单信息</div>
        </div>
        <el-table :data="superviseInfo?.prescriptionTaskList ?? []" style="width: 100%">
          <el-table-column v-for="(column, index) in columns" :key="index" :label="column.title" :prop="column.dataIndex" :width="column.width" />
        </el-table>
      </div>
      <div class="table_box">
        <div class="title">
          <IconVertical />
          <div class="title_text">商城订单信息</div>
        </div>
        <el-table :data="superviseInfo?.tradeDataList ?? []" style="width: 100%">
          <el-table-column v-for="(column, index) in columns_saasOrderInfo" :key="index" :label="column.title" :prop="column.dataIndex" :width="column.width" />
        </el-table>
      </div>
      <div class="table_box">
        <div class="title">
          <IconVertical />
          <div class="title_text">商城订单监管数据</div>
        </div>
        <el-table :data="superviseInfo?.tradeTaskList ?? []" style="width: 100%">
          <el-table-column v-for="(column, index) in columns" :key="index" :label="column.title" :prop="column.dataIndex" :width="column.width" />
        </el-table>
      </div>
    </div>
  </StyledModal>
</template>

<script>
import { reactive, ref, watch } from 'vue'
import StyledModal from '@/components/modal/StyledModal'
import DetailView from '../DetailView/index.vue'
import request from '@/utils/request'
import IconVertical from '@/components/customStyle/IconVertical/index.vue'

export default {
  setup(_, ctx) {
    const visible = ref(false)
    const rxCode = ref('')
    const superviseInfo = ref({}) // 监管信息

    const columns = [
      { title: '任务ID', dataIndex: 'taskId', width: 130 },
      { title: '接入代码', dataIndex: 'servCode', width: 95 },
      { title: '接入名称', dataIndex: 'servName', width: 95 },
      { title: '流水类型', dataIndex: 'seqTypeName', width: 100 },
      { title: '流水号', dataIndex: 'seqNo', width: 170 },
      { title: '归属流水号', dataIndex: 'parentSeqNo', width: 170 },
      { title: '推送状态', dataIndex: 'statusName', width: 90 },
      { title: '入表时间', dataIndex: 'inDateStr', width: 200 },
      { title: '完成时间', dataIndex: 'finishDateStr' },
      { title: '数据内容', dataIndex: 'dataContent', width: 600 },
    ]

    const columns_saasOrderInfo = [
      {
        title: '商城订单号',
        dataIndex: 'mainTradeNo',
        // width: 150
      },
      {
        title: '节点名称',
        dataIndex: 'noticeTypeName',
        // width: 95
      },
      {
        title: '状态',
        dataIndex: 'statusName',
        // width: 95
      },
      { title: '时间', dataIndex: 'noticeDateStr' },
    ]

    watch(rxCode, (v) => {
      if (v) getSuperviseInfo(v)
    })

    function open(pRxCode) {
      visible.value = true
      if (pRxCode) rxCode.value = pRxCode
    }

    function close() {
      rxCode.value = ''
      visible.value = false
    }

    function getSuperviseInfo(rxCode) {
      request({
        url: '/web/system/supervisesdktask/getSuperviseSDKTaskList',
        data: { rxCode },
      }).then((data) => {
        superviseInfo.value = data ?? {}
      })
    }

    return {
      visible,
      open,
      close,
      rxCode,
      superviseInfo,
      columns,
      columns_saasOrderInfo,
    }
  },
  components: { StyledModal, DetailView, IconVertical },
}
</script>

<style lang="less" scoped>
.table_box {
  margin-bottom: 40px;
  .title {
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-bottom: 6px;
    .title_text {
      margin-left: 10px;
    }
  }
}
</style>
